
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { RetrospectiveSession, RetrospectiveParticipant, RetrospectiveTheme } from '../models';
import { defaultThemes } from '../defaults';
import SessionActions from './SessionActions.vue';

@Component({ components: { SessionActions } })
export default class SessionTile extends Vue {
  @Prop({ type: Object as () => RetrospectiveSession, required: true }) readonly value!: RetrospectiveSession;

  formatDate = formatDate;

  get participants(): RetrospectiveParticipant[] {
    return this.value && this.value.people ? Object.values(this.value.people) : [];
  }

  get theme(): RetrospectiveTheme {
    return defaultThemes[this.value.theme];
  }
}
