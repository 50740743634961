











































































































































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { getStatusColor } from '@/utils';
import { RetrospectiveSession, RetrospectiveCards, RetrospectiveColumns, RetrospectiveType, User } from '../models';
import { defaultColors } from '../defaults';
import { retrospectiveService } from '../services';
import ColorPicker from '@/components/ColorPicker.vue';
import ThemeSelector from './ThemeSelector.vue';

@Component({ components: { ColorPicker, ThemeSelector } })
export default class SessionBuilder extends Vue {
  @PropSync('value', { type: Object as () => RetrospectiveSession, required: true })
  session!: RetrospectiveSession;

  defaultColors = defaultColors;
  getStatusColor = getStatusColor;
  config = {};
  cards: RetrospectiveCards = [];
  columns: RetrospectiveColumns = [];
  type: RetrospectiveType | null = null;
  showStories = false;
  valid = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get cardsExist(): boolean {
    return this.cards.length > 0;
  }
  get sessionExists(): boolean {
    return this.session.createdOn !== undefined;
  }
  get retrospectiveTypes(): RetrospectiveType[] {
    if (!this.config['types']) return [];
    const types: RetrospectiveType[] = Object.values(this.config['types']);
    return types.sort((a, b) => (a.id === 'Random' ? -1 : a.id.localeCompare(b.id)));
  }

  @Watch('session.title')
  onTitleChange(title) {
    document.title = title;
  }

  created() {
    this.init();
  }

  async init() {
    await this.$bind('config', retrospectiveService.getConfigRef());
    await this.$bind('cards', retrospectiveService.getCardsRef(this.session.id));
    await this.$bind('columns', retrospectiveService.getColumnsRef(this.session.id));

    if (this.config['types']) {
      this.type = this.config['types'][this.session.type];
      this.overrideColumnsConfig();
    }
    //this.$bus.$emit('loading-indicator', false);
  }

  changeType(type) {
    if (this.config['types']) {
      this.type = this.config['types'][type];
      //this.overrideColumnsConfig();
    }
  }

  overrideColumnsConfig() {
    if (this.type && this.columns && this.columns.length > 0) {
      const cols: RetrospectiveColumns = [];
      this.session.board.columnIds.forEach((id) => {
        const col = this.columns.find((col) => col.id === id);
        if (col) cols.push(col);
      });
      this.type.columns = cols;
    }
  }

  saveSession() {
    // Select a type in case of Random
    if (this.session.type === 'Random' && this.config && this.config['types']) {
      const idx = Math.floor(Math.random() * (this.retrospectiveTypes.length - 2));
      this.session.type = this.retrospectiveTypes[idx].id;
      this.type = this.config['types'][this.session.type];
    }
    //console.log('Save session', this.session, this.type);
    this.$emit('save', this.session, this.type);
  }

  deleteSession() {
    this.$emit('delete', this.session);
  }

  setSessionStatus(status: string) {
    this.session.status = status;
    if (status === 'Active') {
      this.session.completedOn = null;
      this.session.startedOn = Date.now();
    } else if (status === 'Closed') {
      this.session.completedOn = Date.now();
    }
    this.saveSession();
  }
}
