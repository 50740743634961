
import { Vue, Component } from 'vue-property-decorator';
import { RetrospectiveSession, User } from '../models';
import { retrospectiveService } from '../services';
import SessionsPanel from '../components/SessionsPanel.vue';
import SessionImportDialog from '../components/SessionImportDialog.vue';

@Component({ components: { SessionsPanel, SessionImportDialog } })
export default class Dashboard extends Vue {
  config = {};
  showImportDialog = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  created() {
    // Event listeners
    this.$bus.$off('retro-clone').$on('retro-clone', this.onRetrospectiveClone);
    this.$bus.$off('retro-export').$on('retro-export', this.onRetrospectiveExport);
    this.$bus.$off('retro-delete').$on('retro-delete', this.onRetrospectiveDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    document.title = 'AgileMate Retrospectives';
    this.$analytics.logEvent('retro-dashboard');
    this.$bind('config', retrospectiveService.getConfigRef());
  }

  // Event handlers
  async onRetrospectiveClone(session: RetrospectiveSession) {
    try {
      await retrospectiveService.cloneSession(session, this.user);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully cloned', 'success');
      this.$analytics.logEvent('retro-clone', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onRetrospectiveExport(session: RetrospectiveSession) {
    try {
      // downloadJson(session, session.id);
      await retrospectiveService.exportSession(session);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully exported', 'success');
      this.$analytics.logEvent('retro-export', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onRetrospectiveDelete(session: RetrospectiveSession) {
    try {
      await retrospectiveService.deleteSession(session.id);
      this.$bus.$emit('snackbar-notify', 'Retrospective successfully deleted', 'success');
      this.$analytics.logEvent('retro-delete', { session_id: session.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
