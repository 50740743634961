
import { Vue, Component, ModelSync } from 'vue-property-decorator';
import { RetrospectiveSession } from '../models';
import { retrospectiveService } from '../services';

@Component
export default class SessionImportDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  importing = false;
  importFiles: File[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async onSessionImport() {
    try {
      for (let file of this.importFiles) {
        this.importing = true;
        const session: RetrospectiveSession = await retrospectiveService.importSession(file, this.userId);
        await retrospectiveService.createSession(session);
        this.$bus.$emit('snackbar-notify', 'Retrospective successfully imported', 'success');
        this.show = false;
        this.importFiles = [];
        this.$analytics.logEvent('retro-import', { session_id: session.id, user_id: this.userId });
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
    this.importing = false;
  }
}
