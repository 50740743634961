
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RetrospectiveSession } from '../models';

@Component
export default class SessionsActions extends Vue {
  @Prop({ type: Object as () => RetrospectiveSession, required: true }) readonly value!: RetrospectiveSession;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get isOwner(): boolean {
    return this.value.owner === this.userId;
  }
}
