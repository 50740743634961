
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { RetrospectiveSession, RetrospectiveParticipant, RetrospectiveType } from '../models';
import SessionActions from './SessionActions.vue';

@Component({ components: { SessionActions } })
export default class SessionTile extends Vue {
  @Prop({ type: Object as () => RetrospectiveSession, required: true }) readonly value!: RetrospectiveSession;

  @Prop({ type: Object, required: true }) readonly config!: Object;

  formatDate = formatDate;

  get participants(): RetrospectiveParticipant[] {
    return this.value && this.value.people ? Object.values(this.value.people) : [];
  }
  get retrospectiveTypes(): RetrospectiveType[] {
    if (!this.config['types']) return [];
    return Object.values(this.config['types']);
  }
  get type(): RetrospectiveType | null {
    if (!this.config['types']) return null;
    return this.config['types'][this.value.type];
  }
}
